.gpt3__whatgpt3 {
  display: flex;
  flex-direction: column;
  background-color: var(--color-footer);
  display: flex;
  padding: 3rem;

  /* ff 3.6+ */
  background: -moz-linear-gradient(
    136deg,
    rgba(15, 66, 121, 1) 0%,
    rgba(4, 44, 84, 1) 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(25, 118, 219, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    136deg,
    rgba(15, 66, 121, 1) 0%,
    rgba(4, 44, 84, 1) 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(25, 118, 219, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    136deg,
    rgba(15, 66, 121, 1) 0%,
    rgba(4, 44, 84, 1) 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(25, 118, 219, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1976DB', endColorstr='#0F4279', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    136deg,
    rgba(15, 66, 121, 1) 0%,
    rgba(4, 44, 84, 1) 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(25, 118, 219, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    136deg,
    rgba(15, 66, 121, 1) 0%,
    rgba(4, 44, 84, 1) 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(25, 118, 219, 1) 100%
  );
}

.gpt3__whatgpt3-feature .gpt3__feature {
  margin: 0;
}

.gpt3__whatgpt3-feature .gpt3__feature-text,
.gpt3__whatgpt3-intro .gpt3__feature-text {
  max-width: 700px;
  font-size: 16px;
}

.gpt3__whatgpt3-intro .gpt3__feature-heading {
  white-space: nowrap;
  align-self: start;
}

.gpt3__whatgpt3-intro .gpt3__feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.gpt3__whatgpt3-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
  font-size: 34px;
  font-weight: 800;
  font-family: var(--font-family);
  line-height: 45px;
  max-width: 510px;
}

.gpt3__whatgpt3-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
}

.gpt3__whatgpt3-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
}

.gpt3__whatgpt3-container .gpt3__feature-text {
  max-width: 320px;
  margin-top: 1rem;
}

.gpt3__whatgpt3-container .gpt3__feature {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

@media screen and (max-width: 900px) {
  .gpt3__whatgpt3-intro .gpt3__feature {
    flex-direction: column;
  }

  .gpt3__whatgpt3-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .gpt3__whatgpt3-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .gpt3__whatgpt3-feature .gpt3__feature {
    flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .gpt3__whatgpt3-feature .gpt3__features-text {
    margin-top: 0.5rem;
  }

  .gpt3__whatgpt3-heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 400px) {
  /* Customizing feature component as per needs */
  .gpt3__whatgpt3 {
    padding: 2rem 1rem;
  }

  .gpt3__whatgpt3-container {
    gap: 1rem;
  }

  .gpt3__whatgpt3-container .gpt3__feature-heading {
    font-size: 24px;
    line-height: 28px;
  }

  .gpt3__whatgpt3-intro .gpt3__feature-text,
  .gpt3__whatgpt3-container .gpt3__feature-text {
    margin-top: 0rem;
  }

  .gpt3__whatgpt3-intro .gpt3__feature-text p,
  .gpt3__whatgpt3-container .gpt3__feature-text p {
    min-width: 100%;
    line-height: 22px;
  }
}
