.gpt3__possibility {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 4rem;
  padding-top: 0rem;
}

.gpt3__possibilty-image img {
  max-width: 546px;
  max-height: 607.53px;
}

.gpt3__possibilty-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 1rem;
  gap: 1.5rem;
}

.gpt3__possibilty-content > :nth-child(1) {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71e5ff;
}

.gpt3__possibilty-content > :nth-child(2) {
  font-family: var(--font-family);
  font-size: 34px;
  font-weight: 800;
  line-height: 45px;
}

.gpt3__possibilty-content > :nth-child(3) {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--color-text);
}

.gpt3__possibilty-content > :nth-child(4) {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: var(--color-subtext);
}

@media screen and (max-width: 1250px) {
  .gpt3__possibility {
    margin: 0 2rem;
  }
}

@media screen and (max-width: 1190px) {
  .gpt3__possibility {
    margin: 0;
  }
  
  .gpt3__possibilty-image img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media screen and (max-width: 1050px) {
  .gpt3__possibility {
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;
  }
}

@media screen and (max-width: 400px) {
  .gpt3__possibilty-content {
    gap: 1rem;
  }

  .gpt3__possibilty-content > :nth-child(1),
  .gpt3__possibilty-content > :nth-child(3),
  .gpt3__possibilty-content > :nth-child(4) {
    font-size: 12px;
    line-height: 20px;
  }

  .gpt3__possibilty-content > :nth-child(2) {
    font-size: 24px;
    line-height: 30px;
  }
}
