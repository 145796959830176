.gpt3__feature-heading div:nth-child(1) {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.gpt3__feature-heading div:nth-child(2) {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: #ffffff;
}

.gpt3__feature-text p {
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 30px;
  color: #81afdd;
  min-width: 250px;
}
