.gpt3__blog {
  display: flex;
  flex-direction: column;
}

.gpt3__blog-heading {
  width: 100%;
  text-align: left;

  margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
  font-family: var(--font-family);
  font-size: 62px;
  font-weight: 800;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.gpt3__blog-articles {
  display: flex;
  flex-direction: row;
}

.gpt3__blog-articles-A {
  flex: 0.75;
  margin-right: 2rem;
}

.gpt3__blog-articles-B {
  flex: 1;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 1050px) {
  .gpt3__blog-articles {
    flex-direction: column-reverse;
  }

  .gpt3__blog-articles-A {
    display: flex;
    justify-content: center;
  }
  
  .gpt3__blog-articles-A .gpt3__article {
    width: 50%;
  }
  
  .gpt3__blog-articles-A .gpt3__article-image img {
    height: 150px;
  }

  .gpt3__blog-articles-B {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__blog-heading {
    margin-bottom: 2rem;
  }

  .gpt3__blog-heading h1 {
    font-size: 45px;
    line-height: 55px;
  }

  .gpt3__blog-articles-A {
    margin: 0;
  }

  .gpt3__blog-articles-A .gpt3__article {
    width: 100%;
  }

  .gpt3__blog-articles-B {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .gpt3__blog-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
}