.gpt3__article {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  background: var(--color-blog);
}

.gpt3__article-image {
  width: 100%;
  height: 100%;
  background: var(--color-blog);
}

.gpt3__article-image img  {
    width: 100%;
    height: 100%;
}

.gpt3__article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem 1.5rem;
  height: 100%;
}


.gpt3__article-content > div > h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 25.11px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 5rem;
}

.gpt3__article-content p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 11.649px;
  line-height: 35px;
  color: #ffffff;
}

.gpt3__article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
  .gpt3__article-content h3 {
    font-size: 18px;
    line-height: 25px;
  }
}