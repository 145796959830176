.gpt3__header {
  display: flex;
}

.gpt3__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
  /* gap: 2rem; */
}

.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.gpt3__header-content > p {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.gpt3__header-content_input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  position: block;
}

.gpt3__header-content_input input {
  flex: 2;
  width: 100%;
  /* min-width: 430.4px; */
  min-height: 60.7px;
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 300;
  color: #3d6184;
  line-height: 27px;
  background: var(--color-footer);
  border-radius: 5px 0px 0px 5px;
  border: none;
  outline: none;
  padding: 0 1rem;
}

.gpt3__header-content_input input::placeholder {
  color: #3d6184;
}

.gpt3__header-content_input button {
  flex: 0.6;
  width: 100%;
  min-height: 60.7px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  background: #ff4820;
  border: none;
  outline: none;
  border-radius: 0px 5px 5px 0px;
  color: #ffffff;
  padding: 0 1rem;
  cursor: pointer;
  /* 
  width: 184px;
  height: 73px;
  width: 156.6px;
  height: 65.7px;
   */
}

.gpt3__header-content_input_mobile-btn {
  display: none;
}

.gpt3__header-content_people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
}

.gpt3__header-content_people img {
  width: 181.79px;
  height: 38px;
}

.gpt3__header-content_people p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #ffffff;
}

.gpt3__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gpt3__header-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1230px) {
  .gpt3__header {
    flex-direction: column;
  }

  .gpt3__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 750px) {
  .gpt3__header-content h1 {
    font-size: 48px;
    line-height: 55px;
  }

  .gpt3__header-content_people {
    flex-direction: column;
    gap: 0;
  }

  .gpt3__header-content_input input,
  .gpt3__header-content_input button {
    font-size: 16px;
    line-height: 27px;
  }
}

@media screen and (max-width: 650px) {
  .gpt3__header-content h1 {
    font-size: 48px;
    line-height: 55px;
  }

  .gpt3__header-content_people {
    flex-direction: column;
    gap: 0;
  }

  .gpt3__header-content_input input,
  .gpt3__header-content_input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 560px) {
  .gpt3__header-content h1 {
    /* font-size: 48px;
    line-height: 55px; */
    font-size: 36px;
    line-height: 48px;
  }

  .gpt3__header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .gpt3__header-content_input input,
  .gpt3__header-content_input button {
    min-height: 50px;
  }

  .gpt3__header-content_input input {
    font-size: 14px;
    line-height: 24px;
  }

  .gpt3__header-content_input button {
    font-size: 1.8rem;
  }
  .gpt3__header-content_input_btn {
    display: none;
  }

  .gpt3__header-content_input_mobile-btn {
    display: initial;
  }

  .gpt3__header-content_people p {
    text-align: center;
    line-height: 20px;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 415px) {
  .gpt3__header-content h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .gpt3__header-content p {
    font-size: 12px;
    line-height: 18px;
  }

  .gpt3__header-content_input input,
  .gpt3__header-content_input button {
    min-height: 40px;
    font-size: 0.8rem;
  }
  .gpt3__header-content_input button {
    font-size: 1.1rem;
  }
}
